import React, { useState } from "react";
import useWindowSize from "../../utils/useWindowSize";
import * as Animate from "react-reveal"
import Img from "../../assets/images/about/about-2.webp"
import VideoImg from "../../assets/images/about/about.webp"
import VideoModal from "../VideoModal";

export default function ServingAfrica() {
    const { width } = useWindowSize();
    const [show, setShow] = useState(false);
    const handleShow = (val) => {
        setShow(true)
    }

    const handleClose = () => {
        setShow(false)
    }

    return (
        <div className="bg-[#ffffff]">
            <div className="relative container mx-auto px-4 pt-8 md:pt-24 pb-0 lg:pb-2">
                <h2 className="text-center  text-2xl md:text-3xl lg:text-4xl lg:leading-snug font-semibold">
                    We've been Serving Africa for 4 Years and Counting<span className="text-cstm-green">.</span>
                </h2>
                <div className="mt-12 lg:mt-16 flex flex-wrap items-center mb-0 md:mb-10 lg:mb-0">
                    <div className="mb-10 lg:mb-0 w-full lg:w-5/12">
                        <div onClick={handleShow} className="rounded-md h-auto w-full hover:scale-105 transition-all ease-in-out duration-200">
                            <img src={VideoImg} alt="" className="h-full w-full object-cover" />
                        </div>
                    </div>

                    <div className="w-full lg:w-6/12 lg:pl-14 xl:pl-28">
                        <Animate.Fade bottom>
                            <p className=" font-normal text-cstm-grey text-base leading-[30px] lg:text-lg lg:leading-[2.8rem] pt-4 lg:pt-0">
                                Our goal in Africa is to bridge the accessibility gap with limitless and sustainable innovation for quality livelihood.
                                According to the UN, 600 million Africans live without access to steady
                                electricity, which evidently poses a huge challenge. However,
                                affordable access to clean and reliable energy will unlock national
                                and global opportunities for over 70 million Nigerians currently
                                living without power.
                            </p>
                        </Animate.Fade>
                    </div>
                </div>

                <div className="">
                    <div className="">
                        <Animate.Fade bottom>
                            <p className=" font-normal text-cstm-grey text-base leading-[30px] lg:text-lg lg:leading-[2.8rem] pt-4 md:pt-3 lg:pt-8">
                                With our energy products, Reeddi sustainably provides clean
                                energy at a price point that is affordable to individuals and
                                businesses operating in poor energy regions of the world. Our
                                customer-centric, locally structured business model allows us
                                to provide easy access to electricity to Africans and the world at
                                large at an affordable rental or purchase fee.
                                Reeddi’s unique and innovative system of “bridging the
                                accessibility gap” has also led to the invention of Africa’s
                                first fully insured and secure go-to source for equipment rental
                                solutions, TempOwn. TempOwn connects individuals, creatives, and
                                project executives with thousands of equipment renting owners where
                                anything can be rented anytime, anywhere.
                                Our solutions and innovation are structured to meet the daily and essential needs of
                                individuals and small businesses in rural and urban African communities.
                            </p>
                        </Animate.Fade>
                    </div>
                </div>
            </div>

            {
                show && <VideoModal
                    show={show}
                    handleModalClose={handleClose}
                    link={"https://www.youtube.com/embed/wSvzP86tfK8"}
                />
            }
        </div>
    );
}
