import React from "react";
import { values } from "../../utils/constants";
import * as Animate from "react-reveal"

export default function OurValues() {
    return (
        <section className="relative bg-[#ebebeb20]">
            <div className="py-20 md:py-32 lg:py-24 container px-4 mx-auto">
                <Animate.Fade bottom>
                    <p className="block font-medium text-base md:text-base lg:text-lg text-center">
                        What Defines Us
                    </p>
                    <h3 className="font-semibold text-2xl md:text-3xl lg:text-4xl xl:text-5xl md:mt-4 text-center">
                        Our Values<span className='text-cstm-green'>.</span>
                    </h3>
                    <p className="mt-6 w-full md:w-11/12 lg:w-10/12 xl:w-8/12 px-4 lg:px-10 mx-auto text-sm md:text-base lg:text-lg lg:leading-10 text-center text-cstm-grey">
                        We are an impact-driven company. Beyond just building products,
                        we are intentional about the social impact of our
                        products. Our values are hinged on these four precepts:
                    </p>
                </Animate.Fade>
                <div className="mt-10 lg:mt-14 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 xl:gap-6">
                    {values.map((value) => (
                        <div key={value?.id} className="rounded-lg p-4 aboutValueCard">
                            <div className={`h-48 lg:h-64 xl:h-64`}>
                                <Animate.Fade bottom>
                                    <div className="mt-2">
                                        <img src={value.icon} alt={value.title} />
                                    </div>

                                    <p className="font-medium text-base lg:text-lg xl:text-xl mt-4">{value.title}</p>
                                    <p className="mt-2 font-normal text-cstm-grey text-sm lg:text-sm lg:leading-7 pt-6">
                                        {value.content}
                                    </p>
                                </Animate.Fade>
                            </div>
                            <div className="">
                                <img src={value.image} className="w-full object-cover rounded-lg" alt={value.title} />
                            </div>
                        </div>
                    ))}
                </div>

            </div>
        </section>
    );
}
