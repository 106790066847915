import React from "react";
import useWindowSize from "../../utils/useWindowSize";
import Star from "../Star";
import * as Animate from "react-reveal"
import People1 from "../../assets/images/about/about-3.png"

export default function Jumbotron() {
    const { width } = useWindowSize();
    return (
        <main className="bg-[white] pt-10 md:pt-20 relative">
            <div className="md:w-11/12 md:mx-auto m-auto py-16 max-w-7xl">
                <Animate.Fade bottom>
                    <h1 className="mx-auto text-center font-semibold md:font-semibold leading-tight text-3xl md:text-4xl lg:text-5xl capitalize">
                        Limitless & sustainable Innovation<span className="text-cstm-green">.</span>
                    </h1>
                </Animate.Fade>
            </div>

            <div className="w-full relative mt-4">
                <Animate.Fade right>
                    <img
                        className="w-full"
                        src={People1}
                        alt="people"
                    />
                </Animate.Fade>
            </div>
        </main>
    );
}
