import React from "react";
import Layout from "../containers/Layout";
import { Contact, Newsletter, ReeddiInTheMedia } from "../components";
import Jumbotron from "../components/About/Jumbotron";
import ServingAfrica from "../components/About/ServingAfrica";
import OurValues from "../components/About/OurValues";
import { Testimonials } from "../components/ReeddiCapsuleComponents";

export default function About() {
  return (
    <Layout title={"About"}>
      <Jumbotron />
      <ServingAfrica />
      <OurValues />
      <Testimonials />
      <Contact />
      <Newsletter />
    </Layout>
  );
}
